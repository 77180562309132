import { InputMask } from "../Components/InputMask";
import { useState } from "preact/hooks";
import { formatHoras, numeroParaExtenso } from "../utils";

type SubmitStatus = 'idle' | 'pending' | 'success' | 'error';

function formatCurrency(value: string | number) {
    let number = typeof value === 'string' ? parseFloat(value as string) : value;
    number = parseFloat(number.toFixed(2));

    return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
    }).format(number);
}

function formatPlano(value: string) {
	if (value === 'light') return "Light";
	if (value === 'basico') return "Básico";
	if (value === 'intermediario') return "Intermediário";
	if (value === 'avancado') return "Avançado";
	return value;
}

function formatImplantacao(value: string) {
	if (value === 'isento') return "Isento";
	if (value === 'iniciante') return "Iniciante";
	if (value === 'pro') return "Pró";
	if (value === 'master') return "Master";
}

export function Resultado(props) {
    const {
		calculo,
		quantidade_licencas,
		forma_pagamento,
		plano_implantacao,
		plano_escolhido,
		makro_cnd,
		makro_dfe,
		makro_dse,
		makro_whats_api_integracao,
		makro_whats_chatbot,
		makro_whats_qtd_numeros,
		makro_whats_qtd_usuarios,
		valor_mensalidade,
		migracao_dados,
		qtd_funcionarios,
		handleReset,
		showForm = true,
	} = props;
    const [status, setStatus] = useState<SubmitStatus>('idle');
	const [urlResultado, setUrlResultado] = useState("");
	const { valorHospedagem } = calculo;

	console.log(props);

    function handleSubmit(e) {
        e.preventDefault();

        setStatus('pending');
        const form = new FormData(e.currentTarget);
        const body = JSON.stringify({
            ...props,
            ...Object.fromEntries(form.entries()),
        });

        fetch(`https://api-leads.makrosystem.com.br/api/v1/lead`, {
            headers: {
                'content-type': 'application/json',
                'accept': 'application/json',
            },
            method: 'post',
            body
        })
            .then((response) => response.json())
            .then(({ id }) => {
                setStatus('success');
                setUrlResultado(`https://makrosystem.com.br/resultado-simulador/?id=${id}`);
            })
            .catch((error) => {
                setStatus('error');
            });
    }

    return <div className="calculadora__resultado">
		<div className="calculadora__investimento-e-valor-mensal">
			<div className="calculadora__card">
				<div className="calculadora__card__title">
					<h3>Investimento Inicial</h3>
				</div>
				<div className="calculadora__card__body">
					<p>{formatCurrency(calculo.investimentoInicial)}</p>

					{forma_pagamento === 'cartao' && <div>
						Pagamento no <strong>cartão</strong> em até <strong>5x</strong> sem juros ou em até&nbsp;
						<strong>12x</strong> com acréscimo de <strong>1,4%</strong> ao mês.
					</div>}

					{forma_pagamento === 'pix' && <div>
						Pagamento no <strong>Pix</strong>.
					</div>}
				</div>
			</div>

			<div className="calculadora__card">
				<div className="calculadora__card__title">
					<h3>Valor Mensal Total</h3>
				</div>
				<div className="calculadora__card__body">
					<p>{formatCurrency(calculo.mensalidadeTotal)}</p>
				</div>
			</div>
		</div>

		<table className="calculadora__table">
			<thead>
				<tr>
					<th className="calculadora__table__description-col">Você escolheu o plano <strong>{formatPlano(plano_escolhido)}</strong></th>
					<th className="calculadora__table__value-col">Mensalidade</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td className="calculadora__table__description-col">
						{['avancado'].includes(plano_escolhido) && <>
							Com {numeroParaExtenso[parseInt(quantidade_licencas)]} {parseInt(quantidade_licencas) > 1 ? 'licenças exclusivas' : 'licença exclusiva'},
							podendo gerenciar um número ilimitado de empresas,
							capacidade para número ilimitado de funcionários e notas ilimitadas,
							você está pronto(a) para uma integração completa.
						</>}
						{['intermediario'].includes(plano_escolhido) && <>
							Com {numeroParaExtenso[parseInt(quantidade_licencas)]} {parseInt(quantidade_licencas) > 1 ? 'licenças exclusivas' : 'licença exclusiva'},
							podendo gerenciar até <strong>{calculo.qtdEmpresas} empresas</strong>,
							capacidade para <strong>{calculo.qtdFuncionarios} funcionários</strong> e notas ilimitadas,
							você está pronto(a) para uma integração completa.
						</>}
						{!['avancado', 'intermediario'].includes(plano_escolhido) && <>
							Com {numeroParaExtenso[parseInt(quantidade_licencas)]} {parseInt(quantidade_licencas) > 1 ? 'licenças exclusivas' : 'licença exclusiva'}, podendo gerenciar
							até <strong>{calculo.qtdEmpresas} empresas</strong>,
							capacidade para <strong>{calculo.qtdFuncionarios} funcionários</strong> e notas ilimitadas,
							você está pronto(a) para uma integração completa.
						</>}
					</td>
					<td className="calculadora__table__value-col">
						<strong>{formatCurrency(parseFloat(calculo.valorPlano) + parseFloat(calculo.valorLicenca))}</strong>
					</td>
				</tr>
			</tbody>
		</table>

		<table className="calculadora__table">
			<thead>
			<tr>
				<th className="calculadora__table__description-col">Hospedagem</th>
				<th className="calculadora__table__value-col">Mensalidade</th>
			</tr>
			</thead>
			<tbody>
			<tr>
				<td className="calculadora__table__description-col">
					Desfrute de armazenamento ilimitado e backups a cada 2 segundos.
					Esteja respaldado por uma infraestrutura robusta e confiável.
				</td>
				<td className="calculadora__table__value-col">
					<strong>{formatCurrency(parseFloat(valorHospedagem))}</strong>
				</td>
			</tr>
			</tbody>
		</table>

		{makro_cnd === '1' && (<table>
			<thead>
			<tr>
				<th className="calculadora__table__description-col">Makro CND</th>
				<th className="calculadora__table__value-col">Mensalidade</th>
				<th className="calculadora__table__value-col">Investimento (taxa única)</th>
			</tr>
			</thead>
			<tbody>
			<tr>
				<td>Com o Makro CND, suas certidões serão automatizadas, agilizando os processos do seu escritório
					e economizando seu tempo.
				</td>
				<td className="calculadora__table__value-col">
					<strong>{formatCurrency(calculo.mensalidadeCnd)}</strong>
				</td>
				<td className="calculadora__table__value-col">
					<strong>{formatCurrency(calculo.ativacaoCnd)}</strong>
				</td>
			</tr>
			</tbody>
		</table>)}

		{makro_dfe === '1' && (<table>
			<thead>
			<tr>
				<th className="calculadora__table__description-col">Makro DFE</th>
				<th className="calculadora__table__value-col">Mensalidade</th>
				<th className="calculadora__table__value-col">Investimento (taxa única)</th>
			</tr>
			</thead>
			<tbody>
			<tr>
				<td className="calculadora__table__description-col">Com o Makro DFe você consegue baixar, importar,
					manifestar e contabilizar notas automaticamente,
					economizando tempo, ganhando produtividade, obtendo minimização de erros e mais segurança.
				</td>
				<td className="calculadora__table__value-col">
					<strong>{formatCurrency(calculo.mensalidadeDfe)}</strong>
				</td>
				<td className="calculadora__table__value-col">
					<strong>{formatCurrency(calculo.ativacaoDfe)}</strong>
				</td>
			</tr>
			</tbody>
		</table>)}

		{makro_dse === '1' && (<table>
			<thead>
			<tr>
				<th>Makro DSE</th>
				<th className="calculadora__table__value-col">Mensalidade</th>
				<th className="calculadora__table__value-col">Investimento (taxa única)</th>
			</tr>
			</thead>
			<tbody>
			<tr>
				<td>Com o Makro DSe você terá integração automática e importação instantânea e automática
					para o sistema Makro, obtendo maior agilidade, produtividade e minimização de erros.</td>
				<td className="calculadora__table__value-col">
					<strong>{formatCurrency(calculo.mensalidadeDse)}</strong>
				</td>
				<td className="calculadora__table__value-col">
					<strong>{formatCurrency(calculo.ativacaoDse)}</strong>
				</td>
			</tr>
			</tbody>
		</table>)}

		{makro_whats_qtd_numeros > 0 && (<table>
			<thead>
			<tr>
				<th>Makro Whats</th>
				{ !!valor_mensalidade && <th className="calculadora__table__value-col">Mensalidade</th> }
				<th className="calculadora__table__value-col">Investimento (taxa única)</th>
			</tr>
			</thead>
			<tbody>
			<tr>
				<td>
					<p>O Makro Whats é uma ferramenta que centraliza todas as conversas dos colaboradores
						do escritório com os seus clientes, permitindo gerenciar tudo em um só lugar!</p>

					<p>
						Atendendo
						a <strong>{makro_whats_qtd_numeros} {makro_whats_qtd_numeros <= 1 ? 'número' : 'números'}</strong>,&nbsp;
						<strong>{makro_whats_qtd_usuarios} {makro_whats_qtd_usuarios <= 1 ? 'usuário' : 'usuários'}</strong>,&nbsp;
						{parseInt(makro_whats_api_integracao) === 1 ? 'com' : 'sem'} API de integração e&nbsp;
						{parseInt(makro_whats_chatbot) === 1 ? 'com' : 'sem'} chatbot.
					</p>
				</td>
				{ !!valor_mensalidade && <td className="calculadora__table__value-col">
					<strong>R$ {valor_mensalidade}</strong>
				</td> }
				<td className="calculadora__table__value-col">
					<strong>{formatCurrency(calculo.makroWhatsAtivacao)}</strong>
				</td>
			</tr>
			</tbody>
		</table>)}

		{parseInt(migracao_dados) === 1 && (<table>
			<thead>
			<tr>
				<th>Migração de Dados</th>
				<th className="calculadora__table__value-col">Investimento (taxa única)</th>
			</tr>
			</thead>
			<tbody>
			<tr>
				<td>Com a migração de dados você terá uma mudança segura de informações do seu antigo software contábil
					para o nosso sistema, minimizando qualquer risco de perda de informações.
					<br/>
					A migração escolhida foi a de até <strong>{qtd_funcionarios}</strong> funcionários ativos.</td>
				<td className="calculadora__table__value-col">
					<strong>{formatCurrency(calculo.valorMigracao)}</strong>
				</td>
			</tr>
			</tbody>
		</table>)}

		<table>
			<thead>
				<tr>
					<th>Você escolheu o pacote {formatImplantacao(plano_implantacao)}</th>
					<th className="calculadora__table__value-col">Investimento (taxa única)</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>
						<p>Este pacote contempla:</p>
						<ul>
							<li>Onboarding</li>
							<li>
								{plano_implantacao === 'isento' && <>
									<strong>{calculo.horasCortesia} {formatHoras(calculo.horasCortesia)}</strong> de
									capacitação</>}
								{plano_implantacao === 'iniciante' && <><strong>5 horas</strong> de capacitação</>}
								{plano_implantacao === 'pro' && <><strong>9 horas</strong> de capacitação</>}
								{plano_implantacao === 'master' && <><strong>12 horas</strong> de capacitação</>}
							</li>
							<li>
								{['iniciante', 'isento'].includes(plano_implantacao) && <>Acompanhamento de <strong>3
									meses</strong> da equipe de Sucesso do Cliente</>}
								{plano_implantacao === 'pro' && <>Acompanhamento de <strong>3 meses</strong> da equipe
									de Sucesso do
									Cliente</>}
								{plano_implantacao === 'master' && <>Acompanhamento de <strong>3 meses</strong> da
									equipe de Sucesso
									do cliente</>}
							</li>
						</ul>
					</td>
					<td className="calculadora__table__value-col"><strong>{formatCurrency(calculo.valorImplantacao)}</strong></td>
				</tr>
			</tbody>
		</table>

		<hr />

		{ showForm && <>
			{status === 'success'
				? <div>
					<h3>Seus dados foram enviados com sucesso. Entraremos em contato em breve.</h3>
					<p>Acesse o seu resultado <a href={urlResultado}>aqui</a></p>
					<button className="nova_cotacao" onClick={handleReset}>Nova Cotação</button>
				</div> :
				<form onSubmit={handleSubmit}>
					<div>
						<h3>Insira seus dados para entrarmos em contato</h3>

						<fieldset>
							<label htmlFor="nome">Nome <small>*</small></label>
							<input id="nome" type="text" name="nome" required={true}/>
						</fieldset>

						<fieldset>
							<label htmlFor="email">E-mail <small>*</small></label>
							<input id="email" type="email" name="email" required={true}/>
						</fieldset>

						<InputMask
							mask={InputMask.PhoneMask}
							maskOptions={InputMask.PhoneMaskOptions}
							label="Telefone"
							name="telefone"
							required={true}
						/>

						<button disabled={status === 'pending'}>Enviar</button>
						<button className="btn-reset" onClick={handleReset}>Resetar</button>
					</div>
				</form>
			}
		</>}

		<div className="disclaimer">
			* Condições, descontos e valores exclusivos para a contratação do sistema contábil Makro, válida por 30
			dias.
			Qualquer outra contratação avulsa ou em outra data, os valores podem mudar ou sofrer alterações sem aviso
			prévio.
		</div>
	</div>
}
